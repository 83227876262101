<template>
  <div class="data_report">
    <div class="data_report-head">
      <div class="block">
        <el-date-picker
          v-model="value2"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '00:00:00']"
          value-format="yyyy-MM-dd"
          @change="clearDate"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
      <div style="margin-left:20px">
        <el-button type="primary" icon="el-icon-search" @click="search"
          >搜索</el-button
        >
      </div>
    </div>

    <div class="data_report-box">
      <div class="all">
        <div class="all-head">
          <div
            class="all-head-list"
            v-for="(item, index) in allData"
            :key="index"
          >
            <div class="all-head-list-title">
              {{ item.name }}
            </div>
            <div class="all-head-list-num">
              <span style="font-size:15px;" v-show="index">￥</span>
              <span :id="'all-head-list-num-' + index">{{item.num}}</span>
            </div>
            <div class="all-head-list-img">
              <img :src="item.img" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="alls-head">
        <span>营业统计</span>
        <!-- <span
          class="el-icon-menu"
          @click="tabShow(false)"
          :class="tabStatus ? '' : 'alls-head-color'"
        ></span> -->
        <!-- <span
          class="el-icon-pie-chart"
          @click="tabShow(true)"
          :class="tabStatus ? 'alls-head-color' : ''"
        ></span> -->
      </div>
      <div class="alls" v-show="!tabStatus">
        <div class="alls-heads">
          <div class="alls-item alls-item-super">
            <div class="alls-item-super-type">支付方式</div>
            <div class="alls-item-super-line"></div>
            <div class="alls-item-super-type">项目类型</div>
          </div>
          <div class="alls-item">现金</div>
          <div class="alls-item">微信</div>
          <div class="alls-item">支付宝</div>
          <div class="alls-item">银联</div>
          <div class="alls-item">美团</div>
          <div class="alls-item">卡金</div>
          <div class="alls-item">疗程耗卡</div>
        </div>
        <div class="alls-list" v-for="(item, index) in tab" :key="index">
          
          <div class="alls-item">
            <span class="alls-item-name">{{ item.name }}</span>
            <span class="alls-item-pointer" @click="handleShowPayType(item.name)" v-if="index < 5">详情 ></span>
          </div>
          <div
            class="alls-item"
            v-for="(items, indexs) in item.data"
            :key="indexs"
          >
            {{ items }}
          </div>
        </div>
      </div>

      <div class="other" v-show="tabStatus">
        <div class="other-box">
          <div class="other-box-l">
            <div id="alls"></div>
          </div>
          <div class="other-box-r">
            <div id="alls1"></div>
          </div>
        </div>
      </div>

      <div class="other" v-if="0">
        <div class="other-head">
          <div class="other-head-name">交易看板</div>
          <div class="other-head-tab">
            <el-radio-group v-model="tabPosition">
              <el-radio-button label="top">实收构成</el-radio-button>
              <el-radio-button label="right">实收方式</el-radio-button>
              <el-radio-button label="bottom">消耗构成</el-radio-button>
              <el-radio-button label="left">消耗方式</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div class="other-box">
          <div class="other-box-l">
            <el-alert
              title="实际构成按金额排行"
              type="info"
              :closable="false"
              style="margin-bottom:10px;"
            >
            </el-alert>
            <el-table
              :data="tableData"
              :border="$table('border')"
              style="width: 100%"
            >
              <el-table-column prop="date" label="排名"> </el-table-column>
              <el-table-column prop="name" label="构成"> </el-table-column>
              <el-table-column prop="address" label="实收"> </el-table-column>
              <el-table-column prop="address" label="占比"> </el-table-column>
            </el-table>
          </div>
          <div class="other-box-r">
            <el-alert
              title="实际构成按金额占比"
              type="info"
              :closable="false"
              style="margin-bottom:10px;"
            >
            </el-alert>
            <div id="jioayi" class="tubiao"></div>
          </div>
        </div>
      </div>

      <div class="other" v-if="0">
        <div class="other-head">
          <div class="other-head-name">交易看板</div>
          <div class="other-head-tab">
            <!-- <el-radio-group v-model="tabPosition">
              <el-radio-button label="top">实收构成</el-radio-button>
              <el-radio-button label="right">实收方式</el-radio-button>
              <el-radio-button label="bottom">消耗构成</el-radio-button>
              <el-radio-button label="left">消耗方式</el-radio-button>
            </el-radio-group> -->
          </div>
        </div>
        <div class="other-box">
          <div class="other-box-l">
            <!-- <el-alert
              title="实际构成按金额排行"
              type="info"
              :closable="false"
              style="margin-bottom:10px;"
            >
            </el-alert> -->
            <el-table
              :data="tableData"
              :border="$table('border')"
              style="width: 100%"
            >
              <el-table-column prop="date" label="排名"> </el-table-column>
              <el-table-column prop="name" label="构成"> </el-table-column>
              <el-table-column prop="address" label="实收"> </el-table-column>
              <el-table-column prop="address" label="占比"> </el-table-column>
            </el-table>
          </div>
          <div class="other-box-r">
            <!-- <el-alert
              title="实际构成按金额占比"
              type="info"
              :closable="false"
              style="margin-bottom:10px;"
            >
            </el-alert> -->
            <div id="jioayi" class="tubiao"></div>
          </div>
        </div>
      </div>

      <div class="other" v-if="0">
        <div class="other-head">
          <div class="other-head-name">销售排行</div>
          <div class="other-head-tab">
            <el-radio-group v-model="tabPosition">
              <el-radio-button label="top">卡项排行</el-radio-button>
              <el-radio-button label="right">项目排行</el-radio-button>
              <el-radio-button label="bottom">产品排行</el-radio-button>
              <el-radio-button label="left">套餐排行</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div class="other-box">
          <div class="other-box-l" style="width:49.5%;margin-right:1%">
            <el-alert
              title="卡项按销量排行"
              type="info"
              :closable="false"
              style="margin-bottom:10px;"
            >
            </el-alert>
            <el-table
              :data="tableData"
              :border="$table('border')"
              style="width: 100%"
            >
              <el-table-column prop="date" label="排名"> </el-table-column>
              <el-table-column prop="name" label="构成"> </el-table-column>
              <el-table-column prop="address" label="实收"> </el-table-column>
              <el-table-column prop="address" label="占比"> </el-table-column>
            </el-table>
          </div>
          <div class="other-box-r" style="width:49.5%">
            <el-alert
              title="卡项按销量金额排行"
              type="info"
              :closable="false"
              style="margin-bottom:10px;"
            >
            </el-alert>
            <el-table
              :data="tableData"
              :border="$table('border')"
              style="width: 100%"
            >
              <el-table-column prop="date" label="排名"> </el-table-column>
              <el-table-column prop="name" label="构成"> </el-table-column>
              <el-table-column prop="address" label="实收"> </el-table-column>
              <el-table-column prop="address" label="占比"> </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <el-dialog custom-class="staffs-dialog" :title="'员工' + dialogTableTitle + '业绩统计'" :visible.sync="dialogTableVisible">
      <div class="alls-area">
        <div class="alls-heads">
          <div class="alls-item">姓名</div>
          <div class="alls-item">现金</div>
          <div class="alls-item">微信</div>
          <div class="alls-item">支付宝</div>
          <div class="alls-item">银联</div>
          <div class="alls-item">美团</div>
          <div class="alls-item">卡金</div>
          <div class="alls-item">疗程耗卡</div>
        </div>
        <div class="alls-list" v-for="item in staffs" :key="item.staff_id">
          <div class="alls-item">{{ item.staff_name }}</div>
          <div
            class="alls-item"
            v-for="(itemChild, indexChild) in item.yejiList"
            :key="indexChild"
          >
            {{ itemChild }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabPosition: "left",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value2: "",
      allData: [
        { name: "会员", num: "0.00", img: require("../../assets/r/cc.png") },
        { name: "项目", num: "0.00", img: require("../../assets/r/xm.png") },
        { name: "商品", num: "0.00", img: require("../../assets/r/sp.png") },
        { name: "售卡", num: "0.00", img: require("../../assets/r/ck.png") },
        { name: "充值", num: "0.00", img: require("../../assets/r/cz.png") },
        // { name: "套餐卡", num: 99999, num1: 324, num2: 564654 },
      ],
      tableData: [],
      tabStatus: false, //切换表格图标显示
      tab: [
        { id: 1, name: "售卡", data: [], key: "card" },
        { id: 2, name: "充次", data: [], key: "card_recharge" },
        { id: 3, name: "商品", data: [], key: "product" },
        { id: 4, name: "项目", data: [], key: "project" },
        { id: 5, name: "充值", data: [], key: "recharge" },
        { id: 6, name: "收入", data: [], key: "shoru" },
        { id: 7, name: "支出", data: [], key: "zhichu" },
      ],
      dialogTableTitle: '',
      dialogTableVisible: false,
      staffs: [], // 项目员工收支占比弹窗列表
    }
  },
  methods: {
    /**
     * 点击项目类型，显示支付方式
     */
    handleShowPayType(name) {
      this.dialogTableTitle = name;
      let bill_type = '';
      let date1 = '';
      let date2 = '';
      switch (name) {
        case '充值':
          bill_type = 1;
          break;
        case '售卡':
          bill_type = 2;
          break;
        case '充次':
          bill_type = 3;
          break;
        case '商品':
          bill_type = 4;
          break;
        case '项目':
          bill_type = 5;
          break;
        default:
          bill_type = '';
      }
      if (this.value2.length) {
        date1 = this.value2[0];
        date2 = this.value2[1];
      }
      this.$axios({
        href: "/api/app/meiye/finance/staff_payment_report",
        data: {
          bill_type,
          date1: date1,
          date2: date2
        },
      }).then((res) => {
        console.log('showPayType', res);
        if (res.data && res.data.successful === 1) {
          let staffs = res.data.data.staffs;
          const staffsLen = staffs.length;
          for (let i = 0; i < staffsLen; i++) {
            staffs[i].yejiList = [];
            for (let key in staffs[i].yeji) { // 当key值为'11'，代表美团付款营业统计，将其位置调整到'银联'后面
              if (key === '11') {
                staffs[i].yejiList.splice(4, 0, staffs[i].yeji[key]);
              } else {
                staffs[i].yejiList.push(staffs[i].yeji[key]);
              }
            }
          }
          console.log('staffs', staffs);
          this.staffs = staffs;
          this.dialogTableVisible = true;
        } else {
          this.$message({
            message: res.data.message,
            type: 'danger',
          })
        }
      });
    },
    /* 数字动画 */
    nums(obj, dom) {
      let num = obj - 0; //总数
      let step = num / (1000 / 50); //每30ms增加的数值
      let count = 0; //计数器
      let timer = null; //计时器
      let initial = 0;
      let text = document.querySelector(dom); //dom
      if (!text) {
        return "0.00";
      }
      clearInterval(timer);
      timer = setInterval(() => {
        count += step;
        if (count >= num) {
          clearInterval(timer);
          count = num;
        }
        //t未发生改变的话就直接返回
        let t = count;
        if (t == initial) return;
        initial = t;
        text.innerHTML = initial.toFixed(2);
      }, 50);
    },
    /* 搜索 */
    search() {
      //   console.log(this.value2);
      if (!this.value2) {
        this.value2 = [];
      }
      if (!this.value2.length) {
        this.$message({
          message: "请选择日期",
          type: "warning",
        });
        return false;
      }
      this.getList();
    },
    /* 日期清除 */
    clearDate(val) {
      if (!val) {
        this.value2 = [];
        this.getList();
      }
    },
    /* 获取数据 */
    getList() {
      let date1 = null;
      let date2 = null;
      // let _this = this;
      if (this.value2.length) {
        date1 = this.value2[0];
        date2 = this.value2[1];
      }
      this.$axios({
        href: "/api/app/meiye/finance/yingye_pc",
        data: { date1: date1, date2: date2 },
      }).then((res) => {
        console.log('getList', res);
        let indexs = -1;
        // 业绩统计
        for (let key in res.data.data.yeji) {
          indexs++;
          this.allData[indexs].num = res.data.data.yeji[key];
        }
        for (let j = 0; j < this.tab.length; j++) {
          for (let l in res.data.data.yingyeReport) {
            if (l == this.tab[j].key) {
              this.tab[j].data = [];
              for (let k in res.data.data.yingyeReport[l]) {
                if (k === '11') { // 当key值为'11'，代表美团付款营业统计，将其位置调整到'银联'后面
                  this.tab[j].data.splice(4, 0, res.data.data.yingyeReport[l][k]);
                } else {
                  this.tab[j].data.push(res.data.data.yingyeReport[l][k]);
                }
              }
            }
          }
        }
        /* 美团、卡金和疗程没有支出和收入默认添加0 */
        for (let i = 0; i < 3; i++) {
          this.tab[5].data.push(0);
          this.tab[6].data.push(0);
        }
        console.log(this.tab);
      });
    },
    /* 切换显示类型 obj*/
    tabShow() {
      this.tabStatus = false;
      // window.onresize = function() {
      //   myChart.resize();
      // };
    },
    inits(obj) {
      return this.$echarts.init(document.getElementById(obj), "walden");
    },
    alls() {
      let myChart = this.inits("alls");
      let d = this.allData;
      let getname = [
        // "现金",
        // "银联",
        // "微信",
        // "支付宝",
        // "卡金",
        // "疗程耗卡",
      ];
      // var getvalue = [35, 45, 30];
      // var getbl = [35, 45, 30];

      let data = [];
      for (let i = 0; i < d.length; i++) {
        data.push({
          name: d[i].name,
          value: d[i].num,
        });
        getname.push(d[i].name);
      }

      let option = {
        tooltip: {
          trigger: "item",
        },

        legend: {
          type: "scroll",
          orient: "vertical",
          height: "88%",
          right: "10%",
          top: "center",
          itemWidth: 18,
          itemHeight: 18,
          data: getname,
          textStyle: {
            rich: {
              name: {
                fontSize: 15,
                fontWeight: 400,
                //width: 170,
                width: 230,
                height: 35,
                padding: [0, 0, 0, 5],
                color: "#999",
              },
              rate: {
                fontSize: 15,
                fontWeight: 500,
                height: 35,
                width: 40,
                align: "right",
                color: "#656565",
              },
            },
          },
        },
        series: [
          {
            type: "pie",
            radius: ["45%", "60%"],
            center: ["50%", "50%"],
            label: {
              normal: {
                show: false,
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: data,
          },
        ],
      };
      myChart.setOption(option);
      window.onresize = function() {
        myChart.resize();
      };
    },
    jioayis() {
      let myChart = this.inits("alls1");
      let d = this.allData;
      let getname = ["现金", "银联", "微信", "支付宝", "卡金", "疗程耗卡"];

      let data = [];
      for (let i = 0; i < d.length; i++) {
        data.push({
          name: d[i].name,
          value: d[i].num,
        });
        // getname.push(d[i].pay);
      }

      let option = {
        tooltip: {
          trigger: "axis",
        },

        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: getname,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "邮件营销",
            type: "line",
            stack: "总量",
            data: [120, 132, 101, 134, 90, 230, 210],
          },
          {
            name: "联盟广告",
            type: "line",
            stack: "总量",
            data: [220, 182, 191, 234, 290, 330, 310],
          },
          {
            name: "视频广告",
            type: "line",
            stack: "总量",
            data: [150, 232, 201, 154, 190, 330, 410],
          },
          {
            name: "直接访问",
            type: "line",
            stack: "总量",
            data: [320, 332, 301, 334, 390, 330, 320],
          },
          {
            name: "搜索引擎",
            type: "line",
            stack: "总量",
            data: [820, 932, 901, 934, 1290, 1330, 1320],
          },
        ],
      };
      myChart.setOption(option);
      window.onresize = function() {
        myChart.resize();
      };
      // window.onresize = function() {
      //   myChart.resize();
      //   };
    },
  },
  mounted() {
    let _this = this;
    this.alls();
    this.jioayis();
    this.getList();
    window.onresize = function() {
      _this.alls();
      _this.jioayis();
    };
  },
};
</script>

<style lang="scss" scoped>
.data_report {
  width: 100%;
  //   height: 100%;
  min-height: 100%;
  background: #fff;
  &-head {
    width: 100%;
    height: 80px;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #eee;
    margin-bottom: 35px;
    // background: red;
  }
  &-box {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }
}
.all {
  width: 100%;
  height: 100%;
  &-head {
    width: 100%;
    height: 150px;
    display: flex;
    overflow-x: auto;
    // background: red;
    &-list {
      // float: left;
      width: 18%;
      border-radius: 6.5px 6.5px 0px 0px;
      min-width: 220px;
      height: 120px;
      padding: 0 10px;
      margin: 10px 1%;
      box-sizing: border-box;
      //   border-radius: 5px;
      cursor: pointer;
      background: #fff;
      background: #fff;
      border: 1px solid #eee;
      transition: all 0.5s;
      font-size: 13px;
      position: relative;
      &-title {
        width: 100%;
        height: 60%;
        font-size: 16px;
        color: #8088a8;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
      }
      &-num {
        width: 100%;
        height: 40%;
        font-size: 22px;
        font-weight: 800;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      &-img {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        // background: red;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }
    &-list:hover {
      //   margin-top: -1px;
      border-radius: 5px;
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #b3d8ff;
    }
  }
}
#alls,
#alls1 {
  margin: 20px 0;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 500px;
  // background: red;
}
.alls {
  // margin: 20px 0;
  width: 100%;
  height: 560px;
  &-head {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      display: inline-block;
      padding: 0 5px;
      cursor: pointer;
    }
    span:first-child {
      color: #8088a8;
    }
    &-color {
      color: #8088a8;
    }
  }
  &-heads,
  &-list {
    width: 100%;
    height: 60px;
    display: flex;
  }
  &-heads {
    background: #8088a8;
    color: #fff;
  }
  &-list {
    border-bottom: 1px solid #eee;
    transition: all 0.5s;
    // cursor: pointer;
  }
  &-list:nth-child(even) {
    background: #fafafa;
  }

  &-list:hover {
    background: #fafafa;
  }

  &-item {
    width: 100%;
    height: 100%;
    border-right: 1px solid #f2f2f2;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-name {
      width: 50%;
      font-size: 14px;
      color: #000000;
    }
    &-pointer {
      font-size: 12px;
      font-weight: normal;
      color: #8088A8;
    }
    &-pointer:hover {
      cursor: pointer;
    }
  }
  &-list &-item:first-child {
    font-weight: 800;
  }
  &-item:last-child {
    border: none;
  }
  &-heads &-item {
    border: none;
  }
  &-item-super {
    width: 100%;
    height: 100%;
    // padding: 10px;
    // box-sizing: border-box;
    font-size: 13px;
    position: relative;
    display: block;
    &-type {
      width: 100%;
      height: 50%;
      padding: 10px;
      padding-left: 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }
    &-line {
      position: absolute;
      top: 47%;
      left: 39%;
      width: 30%;
      height: 1px;
      background: #eee;
      transform: rotate(45deg);
    }
    &-type:first-child {
      justify-content: flex-end;
    }
    &-type:last-child {
      justify-content: flex-start;
    }
  }
}
.other {
  width: 100%;
  height: 100%;
  &-head {
    width: 100%;
    height: 60px;
    // background: red;
    margin: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &-name {
      width: 50%;
      height: 100%;
      padding: 0 10px;
      font-size: 20px;
      font-weight: 800;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &-tab {
      width: 50%;
      height: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  &-box {
    width: 100%;
    height: 500px;
    display: flex;
    &-l {
      width: 50%;
      height: 100%;
      margin-right: 10px;
    }
    &-r {
      width: 50%;
      height: 100%;
    }
  }
}
.tubiao {
  width: 100%;
  height: 100%;
}
</style>
